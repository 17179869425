/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import {
  getUserAndTokenData,
  userLogin,
  userRegister,
  resetPasswordApi,
  saveNewPasswordByResetTokenApi,
} from '../../utils/apiLogin';
import { setAuthToken, removeAuthToken } from '../../utils/api';

const emptyUserData = { loggedIn: false, licenses: [{ allowedModules: [] }] };

// initial state
const state = () => ({
  token: localStorage.getItem('token') || '',
  userData: emptyUserData,
});

// getters
const getters = {
  userData(state) {
    return state.userData;
  },
  token(state) {
    return state.token;
  },
  loggedInStatus(state) {
    return state.userData.loggedIn;
  },
  releaseChannel(state) {
    return state.userData.releaseChannel;
  },
  licenseType(state) {
    if (Array.isArray(state.userData.licenses) && state.userData.licenses[0] && state.userData.licenses[0].type) {
      return state.userData.licenses[0].type;
    }
    return 'public';
  },
};

// actions
const actions = {
  async getUserAndTokenDataOnPageLoad({ commit }) {
    const token = localStorage.getItem('token');
    if (token) {
      await setAuthToken(token);
    }

    const getUserData = await getUserAndTokenData();
    if (!getUserData.success) {
      commit('message/setMessage', { color: 'error', message: `${getUserData.data.message}` }, { root: true });
      commit('removeLoginStatus');
      return false;
    }
    const { user } = getUserData.data;
    const tokenNew = getUserData.data.token;

    if (user.loggedIn) {
      await setAuthToken(tokenNew);
      commit('setUserToken', tokenNew);
    }
    commit('setUserData', user);
    return true;
  },
  async login({ commit }, userParams) {
    const getUser = await userLogin(userParams.email, userParams.password);
    if (!getUser.success) {
      await this.dispatch('logout');
      commit('message/setMessage', { color: 'error', message: `${getUser.data.message}` }, { root: true });
      return false;
    }
    const { user, token } = getUser.data;

    commit('message/setMessage', { color: 'success', message: 'Log in successfull' }, { root: true });
    commit('setUserData', user);
    commit('setUserToken', token);
    commit('setLoginStatus', true);
    return true;
  },

  async register({ commit }, userParams) {
    const getRegister = await userRegister(userParams.name, userParams.email, userParams.password, userParams.confirmedPassword);
    if (!getRegister.success) {
      await this.dispatch('logout');
      commit('message/setMessage', { color: 'error', message: `${getRegister.data.message}` }, { root: true });
      return false;
    }
    commit(
      'message/setMessage',
      { color: 'success', message: 'Registered successfully. Please check your inbox for email validation.' },
      { root: true }
    );
    return true;
  },
  async logout({ commit }) {
    await commit('removeLoginStatus');
    await this.dispatch('user/getUserAndTokenDataOnPageLoad');
  },

  async resetPassword({ commit }, params) {
    const getReset = await resetPasswordApi(params.email);
    if (!getReset.success) {
      commit('message/setMessage', { color: 'error', message: `${getReset.data.message}` }, { root: true });
      return false;
    }
    commit(
      'message/setMessage',
      {
        color: 'success',
        message: `If ${params.email} exists in our user base we have sent you an e-mail with a link to reset your password. Please check your inbox and maybe the spam folder.`,
      },
      { root: true }
    );
    return true;
  },

  async saveNewPasswordByResetToken({ commit }, params) {
    const getReset = await saveNewPasswordByResetTokenApi(params.password, params.resetToken);
    if (!getReset.success) {
      commit('message/setMessage', { color: 'error', message: `${getReset.data.message}` }, { root: true });
      return false;
    }
    commit(
      'message/setMessage',
      {
        color: 'success',
        message: 'New password saved. You are able to login with you new password now.',
      },
      { root: true }
    );
    return true;
  },
};

// mutations
const mutations = {
  setUserData(state, userData) {
    state.userData = userData;
  },
  setUserToken(state, token) {
    if (!token) {
      localStorage.removeItem('token');
      return;
    }
    setAuthToken(token);
    localStorage.setItem('token', token);
    state.token = token;
  },
  setLoginStatus(state, status) {
    state.userData.loggedIn = status;
  },
  removeLoginStatus(state) {
    removeAuthToken();
    localStorage.removeItem('token');
    state.token = '';
    state.userData = emptyUserData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
