<template>
  <v-dialog v-model="showDialog" overlay-opacity="0" max-width="500px">
    <v-card>
      <v-toolbar class="mb-2" color="primary" dark flat>Hi, while waiting for your result...</v-toolbar>
      <v-card-subtitle>...why not take a moment to understand the results?</v-card-subtitle>
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header> How is the data collected? </v-expansion-panel-header>
          <v-expansion-panel-content>
            The data is collected by using a automated browser: chromium (Puppetteer). It is the open source base for Googles
            Chrome.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Why are some requests not detected, despite i can see them when openeing the page?</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            In most cases it is timeing. The browser waits after page load for a certain amount of time, of all requests/pixel
            being fired. But sometimes pixel loading even does take longer, so they are not recognized by this tool. HOWEVER: this
            might indicate Problems in your implementation, if pixels fire too late for this tool, as the liklihood is high, that
            users switch pages before request is sent.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>How are duplicates calculated?</v-expansion-panel-header>
          <v-expansion-panel-content>
            They are calculated depending on the report. DataLayer:, Tracking Request:, 3rd Party Request:
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="justify-end">
        <v-btn text @click="showDialog = false">👍 OK, understood </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogWaitingForResults',
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
