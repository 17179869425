import { doGet, doPost, getApiUrl } from './api';

const baseUrl = getApiUrl();

async function sendCrawlRequest(crawlerConfigs) {
  const message = {
    urlsToCrawl: [{ url: crawlerConfigs.url }],
    numberOfPagesToCrawl: crawlerConfigs.numberOfPagesToCrawl,
    crawlsInParallel: crawlerConfigs.crawlsInParallel,
    targetCrawler: crawlerConfigs.targetCrawler,
    providerCatsToBlockRequest: crawlerConfigs.providerCatsToBlockRequest,
    jsToEvaluate: crawlerConfigs.jsToEvaluate,
  };

  if (crawlerConfigs.consentSelector) {
    message.consentConfig = {
      consentAction: 'allow',
      urlForConsent: '',
      selectorForConsentBannerClick: crawlerConfigs.consentSelector,
      handleForConsentBannerClick: crawlerConfigs.handleForConsentBannerClick,
      doTheConsentClick: crawlerConfigs.clickTheBanner,
      bannerId: crawlerConfigs.bannerId,
    };
  }

  const requestUrl = `${baseUrl}v2/crawlme/`;
  const response = await doPost(requestUrl, message, true, 10000);
  return response;
}

async function getAppTableByRequestId(crawlRequestId) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/apptable/`);
  return response;
}

async function getLastResult(hostname) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/hostname/${hostname}/latest/apptable/all`);
  return response;
}

async function getCrawlhistoryPublic() {
  const response = await doGet(`${baseUrl}v2/crawlingresult/history/public`);
  return response;
}

async function getCrawlhistoryPrivate() {
  const response = await doGet(`${baseUrl}v2/crawlingresult/history/private`);
  return response;
}

async function getCrawlStatus(crawlRequestId) {
  const response = await doGet(`${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/status/`);
  return response;
}

async function setSharableSettings(crawlRequestId, setting) {
  if (typeof setting !== 'boolean') {
    throw new Error(`setting must be a boolean it is ${typeof setting}, ${setting}`);
  }

  const requestUrl = `${baseUrl}v2/crawlingresult/crawlrequestid/${crawlRequestId}/sharable/${setting}`;
  const response = await doPost(requestUrl, '{}', true);
  return response;
}

export {
  getCrawlhistoryPublic,
  getCrawlhistoryPrivate,
  sendCrawlRequest,
  getLastResult,
  getAppTableByRequestId,
  getCrawlStatus,
  setSharableSettings,
};
