<template>
  <v-overlay :value="showDialog">
    <v-row>
      <v-col class="d-flex justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col> </v-row
    ><v-row>
      <v-col class="d-flex justify-center"> </v-col>
    </v-row>
  </v-overlay>
</template>
<script>
export default {
  name: 'DialogLoadingScreenExistingRequest',
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
  },
};
</script>
