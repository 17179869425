import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: process.env.VUE_APP_URL_REPORT_HISTORY_OVERVIEW,
    name: 'ReportHistoryOverview',
    component: () => import('../pages/ReportHistoryOverview.vue'),
  },
  {
    path: process.env.VUE_APP_URL_ABOUT_THIS_TOOL,
    name: 'AboutThisTool',
    component: () => import('../pages/AboutThisTool.vue'),
  },
  {
    path: process.env.VUE_APP_URL_HOME,
    name: 'HomePage',
    component: () => import('../pages/HomePage.vue'),
  },

  {
    path: process.env.VUE_APP_URL_LEGAL_IMPRINT,
    name: 'Imprint',
    component: () => import('../pages/HtmlPages.vue'),
  },
  {
    path: process.env.VUE_APP_URL_LEGAL_PRIVACY,
    name: 'DataPrivacy',
    component: () => import('../pages/HtmlPages.vue'),
  },
  {
    path: process.env.VUE_APP_URL_LEGAL_TOS,
    name: 'TermsOfService',
    component: () => import('../pages/HtmlPages.vue'),
  },
  {
    path: process.env.VUE_APP_URL_LEGAL_REFUND,
    name: 'FairRefund',
    component: () => import('../pages/HtmlPages.vue'),
  },
  {
    path: `${process.env.VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH}:hostnameToCrawl`,
    name: 'SummaryReporting',
    component: () => import('../pages/SummaryReporting.vue'),
  },
  {
    path: `${process.env.VUE_APP_URL_LOGIN_REGISTER}`,
    name: 'AccountLoginRegister',
    component: () => import('../pages/AccountLoginRegister.vue'),
  },
  {
    path: `${process.env.VUE_APP_URL_EXPLAIN_COMPARE}`,
    name: 'ExplainHowCompare',
    component: () => import('../pages/ExplainHowCompare.vue'),
  },
  {
    path: process.env.VUE_APP_URL_REPORT_HISTORY_OVERVIEW_PRIVATE,
    name: 'ReportHistoryOverviewPrivate',
    component: () => import('../pages/ReportHistoryOverview.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: process.env.VUE_APP_URL_SCHEDULER_LIST,
    name: 'SchedulerOverview',
    component: () => import('../pages/SchedulerOverview.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${process.env.VUE_APP_URL_ACCOUNT_OVERVIEW}`,
    name: 'AccountOverview',
    component: () => import('../pages/AccountOverview.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.token) {
      next({
        path: '/login/',
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
