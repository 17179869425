<template v-slot:prepend>
  <div>
    <v-list-item two-line v-if="loggedIn" @click="$router.push({ name: 'AccountOverview' })">
      <v-list-item-avatar v-if="userData && userData.additionalUserData && userData.additionalUserData.picture">
        <img :src="userData.additionalUserData.picture" />
      </v-list-item-avatar>
      <v-list-item-avatar
        color="primary"
        v-if="!userData || !userData.additionalUserData || !userData.additionalUserData.picture"
      >
        <v-icon dark>{{ mdiAccountCircle }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ userData.name }}</v-list-item-title>
        <v-list-item-title
          ><v-btn class="pl-0" x-small plain color="grey">{{ $t('navigationAccount') }}</v-btn></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <div class="text-center" v-if="!loggedIn">
      <v-sheet class="px-7 pt-4 pb-2 mx-auto text-center d-inline-block" color="blue-grey darken-3" dark>
        <div class="grey--text text--lighten-1 text-body-2 mb-1">{{ $t('navigationLoginHint') }}</div>
        <v-btn
          class="ma-1"
          color="secondary"
          elevation="2"
          outlined
          @click="$router.push({ name: 'AccountLoginRegister', query: { register: 'true' } })"
        >
          {{ $t('Create Account') }}
        </v-btn>
      </v-sheet>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiAccountCircle } from '@mdi/js';

export default {
  name: 'NavigationDrawerTop',
  props: {
    logOut: Boolean,
  },
  data: () => ({ mdiAccountCircle }),
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
  },
  methods: {
    async logMeOut() {
      this.$store.dispatch('user/logout');
      window.location.href = process.env.VUE_APP_URL_HOME;
    },
  },
};
</script>
