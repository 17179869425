/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

// initial state
const state = () => ({
  name: '',
  showDialog: false,
  dialogProps: {},
});

// getters
const getters = {
  name(state) {
    return state.name;
  },
  dialogProps(state) {
    return state.dialogProps;
  },
  crawlRequestIdFromProps(state) {
    return state.dialogProps.crawlRequestId || 'none';
  },
  showDialog(state) {
    return state.showDialog;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setName(state, dialogConfigs) {
    state.name = dialogConfigs.name;
    state.dialogProps = dialogConfigs.data;
    state.showDialog = true;
  },
  updateDialogProps(state, addPropObj) {
    state.dialogProps = { ...state.dialogProps, ...addPropObj };
  },
  setShowDialog(state, showDialog) {
    state.showDialog = showDialog;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
