/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { getApiUrl } from '../../utils/api';

const baseUrl = getApiUrl();

// initial state
const state = () => ({
  screenshotUrl: '',
  screenshotId: 'notSet',
});

// getters
const getters = {
  getScreenshotUrl(state) {
    return `${baseUrl}image/${getters.getScreenshotId(state)}`;
  },
  getScreenshotId(state) {
    return state.screenshotId;
  },
};

// mutations
const mutations = {
  setScreenshotId(state, newScreenshotId) {
    state.screenshotId = newScreenshotId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
