/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

// config() {
//   this.$store.commit('scheduler/setConfig', {});
//   this.$store.dispatch('scheduler/typesToCompareMap', { typesToMap, returnType });
//   return this.$store.getters['scheduler/config'];
// return this.$store.getters['scheduler/mode'];
// },
import { createASchedule, getScheduleById } from '../../utils/apiScheduler';
import { typesToCompareMap } from '../../utils/helpers';
import i18n from '../../i18n';
import { getFormattedDate } from '../../utils/date';

// initial state

const fieldsMapHumanBot = ['typesToCompare', 'monitoringMode'];

const defaultConfigs = {
  monitoringMode: 'allChanges',
  crawlInterval: 'Daily',
  typesToCompare: [],
  mailNotificationSendEnabled: true,
  specificAssetsToMonitor: {},
  thirdPartyDomainsOnly: false,
};

const state = () => ({
  config: defaultConfigs,
  mode: 'create',
});

// getters
const getters = {
  config(state) {
    return mapConfigProperties(state.config, 'human');
  },
  configRaw(state) {
    return state.config;
  },
  mode(state) {
    return state.mode;
  },
};

// actions
const actions = {
  async getConfigFromRemote({ commit, getters }, scheduleId) {
    const schedule = await getScheduleById(scheduleId);

    const remoteConfig = {
      crawlInterval: schedule.crawlIntervalforFrontend,
      monitoringMode: schedule.monitoringMode,
      typesToCompare: schedule.typesToCompare,
      mailNotificationSendEnabled: schedule.mailNotificationSendEnabled,
      specificAssetsToMonitor: schedule.specificAssetsToMonitor,
      thirdPartyDomainsOnly: schedule.thirdPartydomainsOnly || false,
    };

    await commit('setConfigRaw', { ...getters.config, ...remoteConfig });
    return 'finished';
  },
  async createSchedule({ commit, getters }, dialogProps) {
    commit('message/setShowMessage', false, { root: true });

    if (!dialogProps.crawlRequestId) {
      commit(
        'message/setMessage',
        { color: 'error', message: i18n.t('dialogScheduleACheckcomparisonMessageErrorNoRequestId') },
        { root: true }
      );
      return false;
    }

    const scheduleConfig = getters.configRaw;
    scheduleConfig.hourOfDay = 8;
    scheduleConfig.timezoneOffset = timezoneOffset();
    scheduleConfig.crawlRequestIdToCompare = dialogProps.crawlRequestId;
    scheduleConfig.crawlerConfigs = dialogProps.crawlerConfigs;
    scheduleConfig.comparisonIgnore = {
      dataLayerIgnorePaths: [
        'page.products.x.pricewtax',
        'page.products.x.articleId',
        'page.products.x.id',
        'page.products.x.variantId',
      ],
    };
    const result = await createASchedule(scheduleConfig);
    if (!result.success) {
      commit('message/setMessage', { color: 'error', message: `${result.data.message}` }, { root: true });
      return false;
    }

    commit(
      'message/setMessage',
      {
        color: 'success',
        message: i18n.t('dialogScheduleACheckcomparisonMessageSuccess', { nextRun: getFormattedDate(result.data.nextRunUTC) }),
      },
      { root: true }
    );
    return true;
  },
};

// mutations
const mutations = {
  setConfig(state, schedulerConfig) {
    state.config = mapConfigProperties(schedulerConfig, 'bot');
  },
  setConfigRaw(state, schedulerConfig) {
    state.config = schedulerConfig;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  resetConfig(state) {
    state.config = defaultConfigs;
  },
  updateConfigProp(state, message) {
    let { value } = message;
    if (fieldsMapHumanBot.includes(message.prop)) {
      value = typesToCompareMap(message.value, 'bot');
    }
    state.config[`${message.prop}`] = value;
  },
  specificAssetsToMonitor(state, message) {
    const { value } = message;
    state.config.specificAssetsToMonitor[`${message.typeToCompare}`] = value;
  },
};

function timezoneOffset() {
  // return new Date().toString().match(/([\d\w+]+)/g)[7];
  return new Date().getTimezoneOffset();
}

function mapConfigProperties(schedulerConfig, targetAudience) {
  const a = ['typesToCompare', 'monitoringMode'];
  const copy = { ...schedulerConfig };
  for (let i = 0; i < a.length; i += 1) {
    if (!schedulerConfig[`${a[i]}`]) {
      continue;
    }
    copy[`${a[i]}`] = typesToCompareMap(schedulerConfig[`${a[i]}`], targetAudience);
  }
  return copy;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
