<template>
  <v-dialog v-model="showDialog" max-width="1100px">
    <v-card color="grey lighten-4" class="pa-2">
      <div class="d-flex justify-end">
        <v-btn icon @click="showDialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </div>
      <PricingTable class="pa-xs-2" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiClose } from '@mdi/js';
import PricingTable from '../account/PricingTable.vue';

export default {
  name: 'DialogPricingTable',
  components: {
    PricingTable,
  },
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
      VUE_APP_URL_LEGAL_REFUND: () => process.env.VUE_APP_URL_LEGAL_REFUND,
    },
  },
  data() {
    return { mdiClose };
  },
};
</script>
