function getFormattedDate(date) {
  const d = new Date(date);
  const userLang = navigator.language || navigator.userLanguage;

  const formattedDate = d.toLocaleDateString(userLang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return formattedDate;
}

function secondToMinute(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds} Minutes`;
}

export { getFormattedDate, secondToMinute };
