<template>
  <span>
    <FeedBack v-if="messageId === 'feedback'" />
    <v-snackbar
      v-if="messageId !== 'feedback'"
      v-model="showMessage"
      :bottom="true"
      :color="messageColour"
      :multi-line="true"
      :right="true"
      :timeout="timeoutBar"
      dismissible
    >
      {{ messageText }}
      <template v-slot:action="{ attrs }">
        <v-btn style="margin: 0; padding: 0; min-width: 0" v-bind="attrs" dark text @click="closeSnackBar()">x</v-btn>
      </template>
      <a :href="messageActionTarget" style="text-decoration: none"
        ><v-btn v-if="messageActionTarget" dark text>{{ messageActionLabel }}</v-btn></a
      >
    </v-snackbar>
  </span>
</template>
<script>
import FeedBack from './snackbars/FeedBack.vue';

export default {
  name: 'AppSnackbar',
  components: {
    FeedBack,
  },
  data: () => ({}),
  computed: {
    showMessage: {
      get() {
        return this.$store.getters['message/showMessage'];
      },
      set(newValue) {
        this.$store.commit('message/setShowMessage', newValue);
      },
    },
    messageText() {
      return this.$store.getters['message/message'];
    },
    messageId() {
      return this.$store.getters['message/messageId'];
    },
    messageColour() {
      return this.$store.getters['message/messageColor'];
    },
    messageActionTarget() {
      return this.$store.getters['message/messageActionTarget'];
    },
    messageActionLabel() {
      return this.$store.getters['message/messageActionLabel'];
    },
    timeoutBar() {
      if (this.$store.getters['message/messageColor'] !== 'error') {
        return 12000;
      }
      return 12020000;
    },
  },
  methods: {
    closeSnackBar() {
      this.$store.commit('message/setShowMessage', false);
    },
  },
};
</script>
