<template>
  <v-dialog v-model="showDialog" persistent max-width="400">
    <v-card class="pa-4">
      <v-card-title class="headline d-flex justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-card-title>
      <v-card-text class="text-subtitle-1"
        >Scanning some randomly selected pages for cookies, tracking requests and 3rd party requests. <br />Please be patient: May
        take 1-2 Minutes. You can save the URL of this page and come back later.
        <div v-if="!loggedIn">
          <br />
          Create a <strong>free</strong> account to:
          <ul>
            <li>Get notified of any changes made to a website</li>
            <li>See the history of all your crawls.</li>
            <li>Audit FIVE pages per crawl.</li>
            <li>Audit the same domain multiple times per day</li>
            <li>Have a private history.</li>
            <li>Get paid option to crawl unlimited pages</li>
          </ul>
        </div>
      </v-card-text>
      <v-btn
        v-if="!loggedIn"
        @click="
          $store.commit('dialog/setShowDialog', false),
            $router.push({ name: 'AccountLoginRegister', query: { register: 'true' } })
        "
        width="100%"
        color="secondary"
        >Create free Account</v-btn
      >
    </v-card>
  </v-dialog>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiRocketLaunch } from '@mdi/js';

export default {
  name: 'DialogLoadingScreen',
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
    dialogProps() {
      return this.$store.getters['dialog/dialogProps'];
    },
  },
  data: () => ({ mdiRocketLaunch }),
};
</script>
