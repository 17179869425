/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

// initial state
const state = () => ({
  message: '',
  messageColor: 'error',
  showMessage: false,
  messageActionTarget: '',
  messageActionLabel: '',
  messageId: '',
});

// getters
const getters = {
  message(state) {
    return state.message;
  },
  messageColor(state) {
    return state.messageColor;
  },
  messageId(state) {
    return state.messageId;
  },
  showMessage(state) {
    return state.showMessage;
  },
  messageActionTarget(state) {
    return state.messageActionTarget;
  },
  messageActionLabel(state) {
    return state.messageActionLabel;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setMessage(state, messageObject) {
    state.messageId = messageObject.messageId || '';
    state.message = messageObject.message;
    state.messageColor = messageObject.color;
    state.showMessage = true;
    state.messageActionTarget = messageObject.messageActionTarget || '';
    state.messageActionLabel = messageObject.messageActionLabel || '';
  },
  setShowMessage(state, showMessage) {
    state.showMessage = showMessage;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
