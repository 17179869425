<template>
  <v-card>
    <v-card-title>
      {{ typeToCompare }}
      <v-spacer></v-spacer>
    </v-card-title>
    Please select those you want to monitor
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    <v-data-table
      v-model="selected"
      :search="search"
      :headers="headers"
      :items="searchableItems"
      item-key="name"
      show-select
      class="elevation-1"
    >
    </v-data-table>
  </v-card>
</template>
<script>
import { typesToCompareMap } from '../../../utils/helpers';

export default {
  name: 'SpecificConfig',
  props: {
    typeToCompare: String,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
      monitoringModeItems: ['All changes', 'Only new appearing values', 'Specific changes only'],
      searchableItems: [],
      typeToCompareForBots: typesToCompareMap(this.typeToCompare, 'bot'),
    };
  },
  mounted() {
    this.getSearchableItems();
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters['scheduler/config'].specificAssetsToMonitor[`${this.typeToCompareForBots}`] || [];
      },
      set(value) {
        this.$store.commit('scheduler/specificAssetsToMonitor', { value, typeToCompare: this.typeToCompareForBots });
      },
    },
    niceNameProp() {
      const mapIdentifierName = {
        cookies: 'name',
        customScriptResult: 'name',
        externalLinks: 'value',
        localStorage: 'name',
        thirdPartyRequests: 'hostname',
        trackingStats: 'name',
      };
      return mapIdentifierName[`${this.typeToCompareForBots}`];
    },
  },
  methods: {
    getSearchableItems() {
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      const rawDataRaw = dialogProps.summaryResult.table[`${this.typeToCompareForBots}`].rowData;

      for (let i = 0; i < rawDataRaw.length; i += 1) {
        this.searchableItems.push({
          name: rawDataRaw[i][`${this.niceNameProp}`],
          identifier: rawDataRaw[i].identifier,
        });
      }
    },
  },
};
</script>
