import { doPost, doGet, getApiUrl } from './api';

const baseUrl = getApiUrl();

async function userLoginGoogle(authcode) {
  const requestUrl = `${baseUrl}v2/user/login/google/`;
  const response = await doPost(requestUrl, { code: authcode }, true, 12000);
  return response;
}

async function userLogin(email, password) {
  const requestUrl = `${baseUrl}v2/user/login/emailandpass/`;
  const response = await doPost(requestUrl, { email, password }, true, 12000);
  return response;
}

async function userRegister(name, email, password, confirmedPassword) {
  const requestUrl = `${baseUrl}v2/user/register/`;
  const response = await doPost(requestUrl, { name, email, password, confirmedPassword }, true, 12000);
  return response;
}

async function getUserAndTokenData() {
  const requestUrl = `${baseUrl}v2/user/data/`;
  const response = await doGet(requestUrl, true, 12000);
  return response;
}

async function verifyUserEmail(verficationToken) {
  const requestUrl = `${baseUrl}v2/user/verify/email/${verficationToken}/`;
  const response = await doGet(requestUrl, true, 12000);
  return response;
}

async function resetPasswordApi(email) {
  const requestUrl = `${baseUrl}v2/user/password/reset/`;
  const response = await doPost(requestUrl, { email }, true, 12000);
  return response;
}

async function saveNewPasswordByResetTokenApi(newPassword, resetToken) {
  const requestUrl = `${baseUrl}v2/user/password/update/`;
  const response = await doPost(requestUrl, { newPassword, resetToken }, true, 12000);
  return response;
}

export {
  userLogin,
  userRegister,
  getUserAndTokenData,
  userLoginGoogle,
  verifyUserEmail,
  resetPasswordApi,
  saveNewPasswordByResetTokenApi,
};
