import Vue from 'vue';
import VueI18n from 'vue-i18n';
import deMessages from './locales/de.json';
import enMessages from './locales/en.json';

Vue.use(VueI18n);

const messages = {
  de: {
    ...deMessages,
  },
  en: {
    ...enMessages,
  },
};
export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
