function getRowColor(testStatus) {
  if (testStatus === 'delete') {
    return 'red';
  }

  if (testStatus === 'add') {
    return 'yellow';
  }
  return '';
}

// eslint-disable-next-line no-unused-vars
function typesToCompareMap(typesToMap, targetAudience) {
  const map = [
    { human: 'JS evaluation result', bot: 'customScriptResult' },
    { human: 'LocalStorage', bot: 'localStorage' },
    { human: 'Cookies', bot: 'cookies' },
    { human: 'External links', bot: 'externalLinks' },
    { human: 'Tracking Requests', bot: 'trackingRequests' },
    { human: '3rd party requests', bot: 'thirdPartyRequests' },
    { human: 'All changes', bot: 'allChanges' },
    { human: 'Only new appearing values', bot: 'onlyNew' },
    { human: 'Specific changes only', bot: 'onlySpecific' },
    { human: 'Consent Banner', bot: 'bannerFoundOnPage' },
  ];

  let search = 'human';
  if (targetAudience === 'human') {
    search = 'bot';
  }

  if (!Array.isArray(typesToMap)) {
    // eslint-disable-next-line no-param-reassign
    const foundMap = map.find(element => typesToMap === element[`${search}`]);
    if (!foundMap) {
      throw new Error(`map not found for "${typesToMap}" and target: ${targetAudience}, search: ${search}`);
    }
    return foundMap[`${targetAudience}`];
  }
  return typesToMap.map(typeElement => map.find(element => typeElement === element[`${search}`])[`${targetAudience}`]);
}

// eslint-disable-next-line import/prefer-default-export
export { getRowColor, typesToCompareMap };
