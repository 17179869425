<template>
  <div>
    <v-card outlined class="pa-6">
      <!-- Badge -->

      <!-- Price Section -->
      <h3 class="text-h5 font-weight-bold ml-5">{{ price }}</h3>
      <div v-if="savings" class="text-caption font-weight-bold indigo--text text--darken-1 mb-2 ml-5">
        Save -{{ savings }}% per credit
      </div>
      <div v-if="!savings" class="text-caption font-weight-bold indigo--text text--darken-1 mb-2 ml-5">&nbsp;</div>
      <div class="text-body-2 grey--text ml-5 mb-1">{{ credits }} credits</div>
      <div class="text-body-2 grey--text ml-5">{{ pricePerCredit }}</div>
      <v-divider class="my-4"></v-divider>

      <!-- Benefits List -->
      <v-list dense>
        <v-list-item class="text-left">
          <v-icon left color="blue lighten-1">{{ mdiCheckCircle }}</v-icon>
          up to {{ credits }} pages<br />
          per scan.
        </v-list-item>
        <v-list-item>
          <v-icon left color="blue lighten-1">{{ mdiCheckCircle }}</v-icon>
          up to {{ credits }} scans.
        </v-list-item>
        <v-list-item>
          <v-icon left color="blue lighten-1">{{ mdiCheckCircle }}</v-icon>
          no expiration
        </v-list-item>
      </v-list>

      <!-- Call-to-Action Button -->
      <v-btn
        block
        :color="buttonColorClass"
        dark
        class="mt-4"
        :href="`${actionLink}${paymentIdentifierInternal}`"
        target="_blank"
      >
        {{ buttonText }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiCheckCircle } from '@mdi/js';

export default {
  name: 'PricingCard',
  data: () => ({
    mdiCheckCircle,
  }),
  props: {
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
    savings: {
      type: Number,
      required: false,
      default: 0,
    },
    buttonColorClass: {
      type: String,
      required: true,
      default: '€30.00',
    },
    title: {
      type: String,
      required: true,
      default: '€30.00',
    },
    price: {
      type: String,
      required: true,
      default: '€30.00',
    },
    credits: {
      type: Number,
      required: true,
      default: 50,
    },
    pricePerCredit: {
      type: String,
      required: true,
      default: '0.60€ per credit',
    },
    buttonText: {
      type: String,
      required: true,
      default: 'Most Popular',
    },
    actionLink: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    paymentIdentifierInternal() {
      const userData = this.$store.getters['user/userData'];
      return userData.licenses[0].paymentIdentifierInternal;
    },
    VUE_APP_URL_LEGAL_REFUND: () => process.env.VUE_APP_URL_LEGAL_REFUND,
  },
};
</script>

<style scoped>
/* Additional custom styles if necessary */
</style>
