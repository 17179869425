<template>
  <v-overlay :value="overlay">
    <v-row>
      <v-col class="d-flex justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col> </v-row
    ><v-row>
      <v-col class="d-flex justify-center">
        <p>{{ loadingMessage }}</p>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: 'AppOverlay',
  props: {
    loadingMessage: String,
  },
  computed: {
    overlay() {
      if (this.loadingMessage === '') {
        return false;
      }

      return true;
    },
  },
};
</script>
