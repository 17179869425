import { doPost, doGet, getApiUrl } from './api';

const baseUrl = getApiUrl();

async function createASchedule(scheduleConfig) {
  const requestUrl = `${baseUrl}v2/scheduler/create/`;
  const response = await doPost(requestUrl, scheduleConfig, true);
  return response;
}

async function runScheduleNow(scheduleId) {
  const requestUrl = `${baseUrl}v2/scheduler/run-now/${scheduleId}`;
  const response = await doPost(requestUrl, {}, true);
  return response;
}

async function getScheduleList() {
  const requestUrl = `${baseUrl}v2/scheduler/list/`;
  const response = await doGet(requestUrl, true, 4000);
  return response.data;
}

async function getScheduleById(scheduleId) {
  const requestUrl = `${baseUrl}v2/scheduler/id/${scheduleId}`;
  const response = await doGet(requestUrl, true, 4000);
  return response.data;
}

export { createASchedule, getScheduleList, runScheduleNow, getScheduleById };
