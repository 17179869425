<template>
  <v-app-bar app color="white" dark elevation="0">
    <v-btn icon>
      <a style="text-decoration: none" :href="VUE_APP_URL_HOME" id="brandText"
        ><v-img class="mr-2 ml-6" src="/cookie-scanner-logo.png" max-height="40" max-width="40" contain></v-img
      ></a>
    </v-btn>
    <v-toolbar-title style="cursor: pointer"
      ><a style="text-decoration: none" :href="VUE_APP_URL_HOME" id="brandText">Cookie Scanner</a></v-toolbar-title
    >
    <v-btn color="#022f5d" small v-if="loggedIn" class="ml-5" outlined @click="showFeebackForm()"> Help? Feature Request? </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      color="#022f5d"
      v-if="!loggedIn"
      class="mt-0"
      plain
      @click="$router.push({ name: 'AccountLoginRegister', query: { login: 'true' } })"
    >
      {{ $t('Sign in') }}
    </v-btn>
    <v-btn v-if="loggedIn" class="mt-0" color="secondary" depressed @click="showPricingTable()"> Get Credits </v-btn>
    <v-app-bar-nav-icon color="#022f5d" @click="setNavDrawer()"></v-app-bar-nav-icon>
  </v-app-bar>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiHome } from '@mdi/js';

export default {
  name: 'AppHeader',
  data: () => ({ mdiHome }),
  computed: {
    VUE_APP_URL_HOME: () => process.env.VUE_APP_URL_HOME,
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
  },

  methods: {
    setNavDrawer() {
      this.$emit('message', { type: 'navDrawer', value: true });
    },
    showPricingTable() {
      this.$store.commit('dialog/setName', {
        name: 'DialogPricingTable',
        data: {},
      });
    },
    showFeebackForm() {
      this.$store.commit('message/setMessage', {
        color: 'success',
        showMessage: true,
        messageId: 'feedback',
        message: this.$t('schedulerMessageStartSuccess'),
        messageActionTarget: 'target',
        messageActionLabel: 'label',
      });
      this.$store.commit('message/setShowMessage', true);
    },
  },
};
</script>

<style>
#brandText {
  color: #022f5d;
}
</style>
