<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent max-width="390">
      <v-card class="pa-5">
        <v-card-title class="headline">{{ $t('Detect changes faster') }}</v-card-title>
        <v-card-text
          >Get alerted, if something changes on your site. This helps you to detect expensive data privacy issues
          faster.</v-card-text
        >
        <v-card-text>To create a monitoring and alerting please create a free account or login.</v-card-text>

        <v-row>
          <v-col class="v-col-12" align="center">
            <v-btn
              color="primary"
              @click="
                $router.push({ name: 'AccountLoginRegister', query: { register: 'true' } });
                showDialog = false;
              "
            >
              {{ $t('Create a free account') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="v-col-6" align="center">
            <v-btn color="primary" text @click="showDialog = false">
              {{ $t('Cancel') }}
            </v-btn> </v-col
          ><v-col align="center" class="v-col-6">
            <v-btn
              color="primary"
              text
              @click="
                $router.push({ name: 'AccountLoginRegister', query: { login: 'true' } });
                showDialog = false;
              "
            >
              {{ $t('Login') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DialogScheduleATestNotLoggedIn',
  components: {},
  data() {
    return {
      isLoading: false,
      getConfigFromRemoteResult: '1',
      step: 1,
      onAComparisonPageAlready: false,
    };
  },
  async mounted() {
    this.page = 1;
    await this.setMode();
    if (this.$store.getters['scheduler/mode'] === 'create') {
      return;
    }

    const dialogProps = this.$store.getters['dialog/dialogProps'];
    this.getConfigFromRemoteResult = await this.$store.dispatch('scheduler/getConfigFromRemote', dialogProps.scheduleId);
  },
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
  },
  methods: {
    createSchedule() {
      this.isLoading = true;
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      this.$store.dispatch('scheduler/createSchedule', dialogProps);
      this.showDialog = false;
      this.isLoading = false;
    },
    async setMode() {
      let mode = 'create';
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      if (dialogProps.scheduleId) {
        mode = 'edit';
      }

      if (dialogProps.crawlerConfigs.crawlRequestIdToCompare) {
        this.onAComparisonPageAlready = true;
      }
      await this.$store.commit('scheduler/setMode', mode);
    },
  },
};
</script>
