<template>
  <div>
    <DialogComparisonDetails v-if="dialogName === 'DialogComparisonDetails'" />
    <DialogInfoResult v-if="dialogName === 'DialogInfoResult'" />
    <DialogDeleteItem v-if="dialogName === 'DialogDeleteItem'" />
    <DialogScheduleATest :key="crawlRequestId" v-if="dialogName === 'DialogScheduleATest' && loggedIn === true" />
    <DialogScheduleATestNotLoggedIn :key="crawlRequestId" v-if="dialogName === 'DialogScheduleATest' && loggedIn === false" />
    <DialogLoadingScreen v-if="dialogName === 'DialogLoadingScreen'" />
    <DialogLoadingScreenExistingRequest v-if="dialogName === 'DialogLoadingScreenExistingRequest'" />
    <DialogPricingTable v-if="dialogName === 'DialogPricingTable'" />
  </div>
</template>
<script>
import DialogInfoResult from './DialogInfoResult.vue';
import DialogComparisonDetails from './DialogComparisonDetails.vue';
import DialogDeleteItem from './DialogDeleteItem.vue';
import DialogScheduleATest from './DialogScheduleATest/DialogScheduleATest.vue';
import DialogScheduleATestNotLoggedIn from './DialogScheduleATest/DialogScheduleATestNotLoggedIn.vue';
import DialogLoadingScreen from './DialogLoadingScreen.vue';
import DialogLoadingScreenExistingRequest from './DialogLoadingScreenExistingRequest.vue';
import DialogPricingTable from './DialogPricingTable.vue';

export default {
  name: 'index',
  components: {
    DialogInfoResult,
    DialogComparisonDetails,
    DialogDeleteItem,
    DialogScheduleATest,
    DialogScheduleATestNotLoggedIn,
    DialogLoadingScreen,
    DialogLoadingScreenExistingRequest,
    DialogPricingTable,
  },
  data: () => ({}),
  computed: {
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
    dialogName() {
      return this.$store.getters['dialog/name'];
    },
    crawlRequestId() {
      return this.$store.getters['dialog/crawlRequestIdFromProps'];
    },
  },
  methods: {},
};
</script>
