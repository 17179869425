<template>
  <v-navigation-drawer v-model="navDrawer" app temporary right>
    <NavigationDrawerTop @message="messageProxy" />
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <a style="text-decoration: none" :href="VUE_APP_URL_HOME" id="brandText">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ mdiHome }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('navigationHome') }}</v-list-item-title>
          </v-list-item>
        </a>
        <v-list-item :to="VUE_APP_URL_REPORT_HISTORY_OVERVIEW_PRIVATE" v-if="userData.loggedIn">
          <v-list-item-icon>
            <v-icon>{{ mdiTableLock }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('navigationReportHistoryPrivate') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="VUE_APP_URL_SCHEDULER_LIST" v-if="userData.loggedIn">
          <v-list-item-icon>
            <v-icon>{{ mdiCookieClockOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('navigationScheduledTest') }}</v-list-item-title>
        </v-list-item>
        <a style="text-decoration: none" :href="VUE_APP_URL_EXPLAIN_COMPARE" id="brandText">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ mdiCompare }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('How results are compared') }}</v-list-item-title>
          </v-list-item>
        </a>
        <v-list-item v-if="userData.loggedIn" href="mailto:support@cookie-scanner.com" target="_blank">
          <v-list-item-icon>
            <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Need Help, Bugs or Feature Requests') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="VUE_APP_URL_ABOUT_THIS_TOOL">
          <v-list-item-icon>
            <v-icon>{{ mdiInformationOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('aboutThisTool') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="VUE_APP_URL_REPORT_HISTORY_OVERVIEW">
          <v-list-item-icon>
            <v-icon>{{ mdiTableEye }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('navigationReportHistoryPublic') }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append v-if="userData.loggedIn">
      <div class="pa-2">
        <v-btn @click="logMeOut()" block> {{ $t('logOut') }} </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  mdiHome,
  mdiTableLock,
  mdiHelpCircleOutline,
  mdiCookieClockOutline,
  mdiTableEye,
  mdiInformationOutline,
  mdiCompare,
} from '@mdi/js';
import NavigationDrawerTop from './NavigationDrawerTop.vue';

export default {
  name: 'NavigationDrawer',
  props: {
    drawer: Boolean,
  },
  components: {
    NavigationDrawerTop,
  },
  data: () => ({
    mdiHome,
    mdiTableLock,
    mdiHelpCircleOutline,
    mdiCookieClockOutline,
    mdiTableEye,
    mdiInformationOutline,
    mdiCompare,
  }),
  computed: {
    VUE_APP_URL_ABOUT_THIS_TOOL: () => process.env.VUE_APP_URL_ABOUT_THIS_TOOL,
    VUE_APP_URL_REPORT_HISTORY_OVERVIEW: () => process.env.VUE_APP_URL_REPORT_HISTORY_OVERVIEW,
    VUE_APP_URL_REPORT_HISTORY_OVERVIEW_PRIVATE: () => process.env.VUE_APP_URL_REPORT_HISTORY_OVERVIEW_PRIVATE,
    VUE_APP_URL_SCHEDULER_LIST: () => process.env.VUE_APP_URL_SCHEDULER_LIST,
    VUE_APP_URL_HOME: () => process.env.VUE_APP_URL_HOME,
    VUE_APP_URL_EXPLAIN_COMPARE: () => process.env.VUE_APP_URL_EXPLAIN_COMPARE,
    userData() {
      return this.$store.getters['user/userData'];
    },
    navDrawer: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        return this.setNavDrawer(newValue);
      },
    },
  },
  methods: {
    setNavDrawer(newValue) {
      this.$emit('message', { type: 'navDrawer', value: newValue });
    },
    logMeOut() {
      this.$store.dispatch('user/logout');
      window.location.href = process.env.VUE_APP_URL_HOME;
    },
    messageProxy(message) {
      this.$emit('message', message);
    },
  },
};
</script>
