<template>
  <v-dialog v-model="showDialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">{{ $t('dialogDeleteMessage') }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="showDialog = false">{{ $t('buttonCancel') }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItem">{{ $t('buttonOk') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { deleteEntry } from '../../utils/api';

export default {
  name: 'DialogDeleteItem',
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
  },
  data: () => ({}),
  methods: {
    async deleteItem() {
      const deletionObject = this.$store.getters['dialog/dialogProps'];
      this.$store.commit('message/setShowMessage', false);
      const result = await deleteEntry(deletionObject.ressource, deletionObject.keyId);
      if (!result.success) {
        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${result.data.message}`,
        });
        return;
      }
      this.$store.commit('message/setMessage', {
        color: 'success',
        message: this.$t('dialogDeleteMessageSuccess'),
      });
      this.showDialog = false;
    },
  },
};
</script>
