import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import GAuth from 'vue-google-oauth2';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/index';
// import config from './config';
import store from './store/index';
import i18n from './i18n';

// const gauthOption = {
//   clientId: config.clientId,
//   scope: 'profile email',
//   prompt: 'select_account',
// };

// Vue.use(GAuth, gauthOption);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#vueapp');
