<template>
  <v-snackbar v-model="showMessage" :timeout="3000000" right>
    <v-card color="#333333" flat>
      <v-card-title>Your opinion matters!</v-card-title>
      <v-card-text>
        <p>
          Tell us what you'd love to see in Cookie-Scanner. Every idea helps us improve. Use this form, Email us or add your
          thoughts to our
          <a target="_blank" style="color: #90d5ff" href="https://lumivote.com/backlog/cookie-scanner/">public backlog</a>.
        </p>
        <v-textarea v-model="feedback.comment" rows="3"></v-textarea>
        <v-checkbox
          v-model="feedback.newsletter"
          label="Yes, you are allowed to send follow up questions to my account e-mail."
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :loading="btnLoadAnimation" type="submit" @click="sendFeedbackNow()" color="primary">Submit Feedback</v-btn>
      </v-card-actions>
    </v-card>
    <template v-slot:action="{ attrs }">
      <v-btn
        style="position: absolute; top: 1px; right: 5px; margin: 0; padding: 0; min-width: 0"
        color="pink"
        text
        v-bind="attrs"
        @click="closeSnackBar()"
        ><v-icon> {{ mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiClose } from '@mdi/js';
import { sendFeedback } from '../../utils/apiFeedback';

export default {
  data: () => ({
    feedback: {
      rating: null,
      comment: '',
      newsletter: false,
    },
    mdiClose,
    btnLoadAnimation: false,
    valid: true,
    name: '',
    nameRules: [v => !!v || 'Name is required', v => (v && v.length <= 10) || 'Name must be less than 10 characters'],
    email: '',
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    select: null,
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkbox: false,
  }),
  computed: {
    showMessage: {
      get() {
        return this.$store.getters['message/showMessage'];
      },
      set(newValue) {
        this.$store.commit('message/setShowMessage', newValue);
      },
    },
  },
  methods: {
    closeSnackBar() {
      this.$store.commit('message/setShowMessage', false);
      localStorage.setItem('feedbackNotWanted', Date.now());
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    sendFeedbackNow() {
      this.btnLoadAnimation = true;
      sendFeedback(this.feedback).then(() => {
        this.$store.commit('message/setMessage', {
          message: 'Thanks a lot for your feedback!',
          color: 'success',
        });
        this.$store.commit('message/setShowMessage', true);
        this.btnLoadAnimation = true;
      });
      localStorage.setItem('feedbackGiven', Date.now());
    },
  },
};
</script>
