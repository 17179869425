<template>
  <v-card-text>
    <v-form ref="simpleScheduleConfigForm">
      <v-container>
        <v-row>
          <v-select
            v-model="crawlInterval"
            :items="crawlIntervalItems"
            label="Interval for Scheduler"
            hint="How often do you want to execute this test?"
            :rules="[rules.crawlIntervalValidation]"
            dense
            solo
          ></v-select>
        </v-row>
        <v-row>
          <v-select
            v-model="typesToCompare"
            :items="typesToCompareItems"
            label="What do you want to monitor?"
            multiple
            chips
            hint="Please choose what you want to monitor."
            :rules="[rules.requiredArray]"
            @keypress.enter="show"
            persistent-hint
          ></v-select>
        </v-row>
        <v-row v-if="typesToCompare.includes(`3rd party requests`) && releaseChannel !== 'beta'" class="pt-6">
          <v-alert border="left" colored-border color="primary" elevation="2">
            ☝️ Third party request monitoring can cause a lot of alerts, as appearently the loading of those scripts is not very
            reliable. So it might happen, that sometimes the a 3rd Party script is not loaded, and the next day it is. It depends
            heavily on the website and which scripts are included. But give it a try.
          </v-alert>
        </v-row>
        <v-row v-if="typesToCompare.includes(`3rd party requests`) && releaseChannel === 'beta'">
          <v-card-text>
            Third party request monitoring can cause a lot alerts, as appearently the loading of those scripts is not very
            reliable. So it might happen, that sometimes the a 3rd Party script is not loaded, the next day it is. If you are
            experiencing problems it might help to only monitor 3rd Party domains and not hostnames.
          </v-card-text>
          <v-checkbox v-model="thirdPartyDomainsOnly" :label="$t(`Monitor domains instead of hostnames`)"></v-checkbox>
        </v-row>
        <v-row class="pt-6">
          <v-select
            v-model="monitoringMode"
            @change="changeMonitoringMode()"
            :items="monitoringModeItems"
            label="Please select."
            hint="What changes shall be monitored?"
            dense
            solo
          ></v-select>
        </v-row>
        <v-row v-if="monitoringMode === 'Specific changes only' ? true : false">
          Please click next to configure the details.
        </v-row>
        <v-row>
          <v-checkbox
            v-model="mailNotificationSendEnabled"
            :label="`Notify me per mail, if there are differences detected.`"
          ></v-checkbox>
        </v-row>
      </v-container> </v-form
  ></v-card-text>
</template>
<script>
import { getAppTableByRequestId } from '../../../utils/apiData';

export default {
  name: 'SimpleConfig',
  data() {
    return {
      crawlIntervalItems: ['Daily', 'Weekly', 'Monthly'],
      rules: {
        requiredArray: value => {
          if (!value) {
            return 'This field is required.';
          }
          if (Array.isArray(value) && value.length > 0) {
            return true;
          }
          return 'This field is required.';
        },
        crawlIntervalValidation: value => {
          const licenseType = this.$store.getters['user/licenseType'];
          if (value !== 'Daily' && licenseType === 'free') {
            return `Please upgrade your license for another interval then daily.`;
          }
          return true;
        },
      },
    };
  },
  async mounted() {
    this.changeMonitoringMode();
  },
  computed: {
    monitoringModeItems() {
      const items = ['All changes'];
      if (this.releaseChannel !== 'stable') {
        items.push('Only new appearing values');
        items.push('Specific changes only');
      }
      return items;
    },
    typesToCompareItems() {
      const typesToCompareItems = ['Cookies', 'LocalStorage', 'Tracking Requests', '3rd party requests', 'External links'];
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      if (dialogProps.crawlerConfigs.jsToEvaluate) {
        typesToCompareItems.push('JS evaluation result');
      }
      if (dialogProps.crawlerConfigs.consentConfig && dialogProps.crawlerConfigs.consentConfig.bannerId !== 'noBanner') {
        typesToCompareItems.push('Consent Banner');
      }
      return typesToCompareItems;
    },
    monitoringMode: {
      get() {
        return this.$store.getters['scheduler/config'].monitoringMode;
      },
      set(value) {
        this.$store.commit('scheduler/updateConfigProp', { value, prop: 'monitoringMode' });
      },
    },
    thirdPartyDomainsOnly: {
      get() {
        return this.$store.getters['scheduler/config'].thirdPartyDomainsOnly;
      },
      set(value) {
        this.$store.commit('scheduler/updateConfigProp', { value, prop: 'thirdPartyDomainsOnly' });
      },
    },
    crawlInterval: {
      get() {
        return this.$store.getters['scheduler/config'].crawlInterval;
      },
      set(value) {
        this.$store.commit('scheduler/updateConfigProp', { value, prop: 'crawlInterval' });
      },
    },
    mailNotificationSendEnabled: {
      get() {
        return this.$store.getters['scheduler/config'].mailNotificationSendEnabled;
      },
      set(value) {
        this.$store.commit('scheduler/updateConfigProp', { value, prop: 'mailNotificationSendEnabled' });
      },
    },
    typesToCompare: {
      get() {
        return this.$store.getters['scheduler/config'].typesToCompare;
      },
      set(value) {
        this.$store.commit('scheduler/updateConfigProp', { value, prop: 'typesToCompare' });
      },
    },
    releaseChannel() {
      return this.$store.getters['user/releaseChannel'];
    },
  },
  methods: {
    changeMonitoringMode() {
      if (this.monitoringMode !== 'Specific changes only') {
        return;
      }
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      if (dialogProps.summaryResult) {
        return;
      }
      this.updateSummaryInStore(dialogProps.crawlRequestId);
    },
    async updateSummaryInStore(crawlRequestId) {
      const summaryResult = await getAppTableByRequestId(crawlRequestId);
      this.$store.commit('dialog/updateDialogProps', { summaryResult: summaryResult.data });
    },
  },
};
</script>
