import { doPost, getApiUrl } from './api';

const baseUrl = getApiUrl();

async function sendFeedback(feedback) {
  const requestUrl = `${baseUrl}v2/feedback/`;
  const response = await doPost(requestUrl, feedback, true);
  return response;
}

// eslint-disable-next-line import/prefer-default-export
export { sendFeedback };
