import axios from 'axios';

const baseUrl = getApiUrl();

async function deleteEntry(ressource, keyId, timeout) {
  let url = baseUrl;
  switch (ressource) {
    case 'schedulerList':
      url += `v2/scheduler/id/${keyId}`;
      break;
    case 'pageCrawlEntry':
      url += `v2/crawlingresult/crawlrequestid/${keyId}/`;
      break;
    default:
      url = '';
  }

  let response;
  const axiosConfig = {};
  axiosConfig.withCredentials = true;
  axiosConfig.timeout = 2000;
  if (timeout) {
    axiosConfig.timeout = timeout;
  }
  try {
    response = await axios.delete(url, axiosConfig);
  } catch (error) {
    return errorResponseHandler(error);
  }
  return returnMessage(response.data, response.status);
}

async function doGet(url, withCreds, timeout) {
  let response;
  const axiosConfig = {};
  axiosConfig.withCredentials = true;
  if (withCreds === false) {
    axiosConfig.withCredentials = false;
  }
  if (timeout) {
    axiosConfig.timeout = timeout;
  }

  try {
    response = await axios.get(url, axiosConfig);
  } catch (error) {
    return errorResponseHandler(error);
  }
  return returnMessage(response.data, response.status);
}

async function doPost(url, message, withCreds, timeout) {
  let response;
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  };

  axiosConfig.withCredentials = true;
  if (withCreds === false) {
    axiosConfig.withCredentials = false;
  }

  if (timeout) {
    axiosConfig.timeout = timeout;
  }

  try {
    response = await axios.post(url, message, axiosConfig);
  } catch (error) {
    return errorResponseHandler(error);
  }
  return returnMessage(response.data, response.status);
}

function setAuthToken(token) {
  axios.defaults.headers.common.Authorization = token;
}

function removeAuthToken() {
  delete axios.defaults.headers.common.Authorization;
}

function errorResponseHandler(catchedError) {
  if (!catchedError.response) {
    return returnErrorMessage({ message: responseCodeMapper(catchedError.code, catchedError.message), messageId: '' });
  }

  if (catchedError.response.status === 404) {
    return returnErrorMessage({ message: false, messageId: '404' });
  }

  if (typeof catchedError.response.data !== 'object' || catchedError.response.data === null) {
    // eslint-disable-next-line no-console
    console.error(`Error response must be an object, got: ${typeof catchedError.response.data}`, catchedError.response.data);
  }

  return returnErrorMessage(
    {
      message: responseCodeMapper(catchedError.code, catchedError.response.data.message),
      messageId: catchedError.response.data.messageId,
    },
    catchedError.response.status
  );
}

function returnMessage(message, status) {
  return {
    success: true,
    data: message,
    status,
  };
}

function returnErrorMessage(message, status) {
  return {
    success: false,
    data: message,
    status,
  };
}

function responseCodeMapper(code, message) {
  const mapper = {
    ECONNABORTED: `Server Issues: ${message}`,
  };

  if (Object.prototype.hasOwnProperty.call(mapper, code)) {
    return mapper[code];
  }

  return message;
}

function getApiUrl() {
  const { hostname } = window.location;
  if (hostname === 'cookie-scanner.com') {
    return 'https://apppi.cookie-scanner.com/';
  }

  if (hostname === 'test.cookie-scanner.com') {
    return 'https://testapppi.cookie-scanner.com/';
  }

  return 'http://localhost:8081/';
}

export { doGet, doPost, deleteEntry, setAuthToken, removeAuthToken, getApiUrl };
