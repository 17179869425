<template>
  <v-container fluid>
    <v-container class="d-flex flex-column align-center justify-center fill-height text-center">
      <!-- Badge -->
      <v-chip class="mb-4" color="blue lighten-4" text-color="blue darken-2" outlined>
        <v-icon left>{{ mdiFlash }}</v-icon>
        No subscription
      </v-chip>

      <!-- Title -->
      <h1 class="font-weight-bold text-h4 mb-2">Stay compliant</h1>

      <!-- Subtitle -->
      <p class="text-body-1 grey--text">
        Purchase credits and use them whenever you want. Credits never expire unless you cancel your account. Each credit allows
        you to audit one page.
      </p>
    </v-container>

    <v-row dense>
      <v-col v-for="card in cards" :key="card.title" xs="12">
        <v-icon left>mdi-flash</v-icon>
        <PricingCard
          :title="card.title"
          :price="card.price"
          :credits="card.credits"
          :pricePerCredit="card.pricePerCredit"
          :buttonText="card.buttonText"
          :buttonColorClass="card.buttonColorClass"
          :savings="card.savings"
          :highlight="card.highlight"
          :actionLink="card.actionLink"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        ><p class="text-body-1 grey--text text-center">
          When you select a plan, you'll be securely redirected to our payment partner, Stripe.com, to complete your purchase.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiFlash } from '@mdi/js';

import PricingCard from '../PricingCard.vue';

export default {
  name: 'PricingTable',
  components: {
    PricingCard,
  },
  props: {},
  data: () => ({
    mdiFlash,
    cards: [
      {
        title: 'Starter',
        price: '€10.00',
        credits: 1000,
        pricePerCredit: '€0.01 per credit',
        buttonText: 'Starter',
        buttonColorClass: 'grey darken-4',
        actionLink: `${process.env.VUE_APP_STRIPE_100_CREDITS}?client_reference_id=`,
        savings: 0,
      },
      {
        title: 'Go Pro',
        price: '€15.00',
        credits: 2000,
        pricePerCredit: '€0.0075 per credit',
        buttonText: 'Go Pro',
        buttonColorClass: 'blue darken-4',
        actionLink: `${process.env.VUE_APP_STRIPE_200_CREDITS}?client_reference_id=`,
        savings: 25,
        highlight: true,
      },
      {
        title: 'Enterprise',
        price: '€50.00',
        credits: 10000,
        pricePerCredit: '€0.005 per credit',
        buttonText: 'Enterprise',
        buttonColorClass: 'grey darken-4',
        actionLink: `${process.env.VUE_APP_STRIPE_1000_CREDITS}?client_reference_id=`,
        savings: 50,
      },
    ],
  }),
  computed: {
    paymentIdentifierInternal() {
      const userData = this.$store.getters['user/userData'];
      return userData.licenses[0].paymentIdentifierInternal;
    },
    VUE_APP_URL_LEGAL_REFUND: () => process.env.VUE_APP_URL_LEGAL_REFUND,
  },
};
</script>
