<template>
  <v-app>
    <AppHeader @message="messageReceiver" />
    <AppSnackbar @message="messageReceiver" />
    <AppOverlay v-bind:loadingMessage="loadingMessage" />

    <NavigationDrawer v-bind:drawer="drawer" @message="messageReceiver" />
    <v-main>
      <router-view @message="messageReceiver" :key="`${$route.path}${ucrid}${loggedIn}`" />
    </v-main>
    <v-btn fab dark large color="#333333" fixed right bottom @click="showFeebackForm()">
      <v-icon dark>{{ mdiMessageTextOutline }}</v-icon>
    </v-btn>
    <AppFooter />
    <Dialogs />
  </v-app>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiMessageTextOutline } from '@mdi/js';
import AppHeader from './components/AppHeader.vue';
import AppSnackbar from './components/AppSnackbar.vue';
import AppOverlay from './components/AppOverlay.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import AppFooter from './components/AppFooter.vue';
import Dialogs from './components/dialogs/index.vue';

export default {
  name: 'app',
  components: {
    AppHeader,
    AppSnackbar,
    AppOverlay,
    NavigationDrawer,
    AppFooter,
    Dialogs,
  },
  data: () => ({
    loadingMessage: '',
    drawer: false,
    mdiMessageTextOutline,
  }),
  computed: {
    loggedIn() {
      return this.$store.getters['user/loggedInStatus'];
    },
    ucrid() {
      return this.$route.query.ucrid;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('user/getUserAndTokenDataOnPageLoad');
  },
  methods: {
    messageReceiver(message) {
      if (message.type && message.type === 'overlay') {
        this.loadingMessage = message.value;
      }

      if (message.type && message.type === 'navDrawer') {
        this.drawer = message.value;
      }
    },
    showFeebackForm() {
      this.$store.commit('message/setMessage', {
        showMessage: true,
        messageId: 'feedback',
      });
      this.$store.commit('message/setShowMessage', true);
    },
  },
};
</script>
<style>
.container {
  max-width: 1400px;
}

.app-bar {
  max-width: 1400px;
}
</style>
