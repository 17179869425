<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent max-width="390">
      <v-card>
        <v-card-title class="headline">{{ headline }}</v-card-title>
        <v-card-text v-if="onAComparisonPageAlready !== true">{{ explainText }} </v-card-text>
        <v-card-text v-if="$store.getters['scheduler/mode'] === 'create' && onAComparisonPageAlready === true"
          >{{
            $t(
              'It is currently not possible to create a monitor on a compared result. Please create a fresh crawl, or go to the initial scan result to create another monitor.'
            )
          }}<br />
          <a :href="hrefInitialResult">{{ $t(`Initial scan result`) }}</a>
        </v-card-text>
        <v-card-text v-if="onAComparisonPageAlready !== true"
          >When a monitor is created, it will crawl regulary the same pages and compare the new result with this one. If changes
          are detected you will get notified.</v-card-text
        >
        <v-card-text v-if="onAComparisonPageAlready !== true"
          >{{
            $t(
              'Please see documentation for more details how and what is compared exactly. Your settings will remain if you use "browser back" button:'
            )
          }}
          <a
            target="_blank"
            @click="
              $router.push({
                name: 'ExplainHowCompare',
              });
              showDialog = false;
            "
            >documentation</a
          ></v-card-text
        >
        <SimpleConfig :key="getConfigFromRemoteResult" v-if="step === 1 && onAComparisonPageAlready === false" />
        <SpecificConfig
          :key="step"
          v-bind:typeToCompare="typeToCompareItems[step - 2]"
          v-if="showSpecificConfig && onAComparisonPageAlready === false"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showDialog = false"> {{ $t('buttonCancel') }} </v-btn>
          <v-btn v-if="step > 1" color="primary" @click="step -= 1"> Back </v-btn>
          <v-btn v-if="allDone && this.onAComparisonPageAlready === false" color="primary" @click="createSchedule">
            {{ $t('buttonSave') }}
          </v-btn>
          <v-btn v-if="!allDone" color="primary" @click="step += 1"> Next </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import SimpleConfig from './SimpleConfig.vue';
import SpecificConfig from './SpecificConfig.vue';

export default {
  name: 'DialogScheduleATest',
  components: {
    SimpleConfig,
    SpecificConfig,
  },
  data() {
    return {
      isLoading: false,
      getConfigFromRemoteResult: '1',
      step: 1,
      onAComparisonPageAlready: false,
    };
  },
  async mounted() {
    this.page = 1;
    await this.setMode();
    if (this.$store.getters['scheduler/mode'] === 'create') {
      return;
    }

    const dialogProps = this.$store.getters['dialog/dialogProps'];
    this.getConfigFromRemoteResult = await this.$store.dispatch('scheduler/getConfigFromRemote', dialogProps.scheduleId);
  },
  computed: {
    allDone() {
      if (this.monitoringMode !== 'Specific changes only') {
        return true;
      }
      if (this.typeToCompareItems.length === this.step - 2) {
        return true;
      }
      return false;
    },
    hrefInitialResult() {
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      return `${process.env.VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH}${dialogProps.summaryResult.startHostname}?ucrid=${dialogProps.summaryResult.crawlRequestIdToCompare}`;
    },
    showSpecificConfig() {
      return this.step > 1 && this.typeToCompareItems.length > this.step - 2;
    },
    typeToCompareItems() {
      return this.$store.getters['scheduler/config'].typesToCompare;
    },
    monitoringMode() {
      return this.$store.getters['scheduler/config'].monitoringMode;
    },
    headline() {
      if (this.$store.getters['scheduler/mode'] === 'create') {
        return `${this.$t('Create a new monitor')}`;
      }
      return `${this.$t('Create a new monitor')}`;
    },
    explainText() {
      if (this.$store.getters['scheduler/mode'] === 'create' && this.onAComparisonPageAlready === false) {
        return `${this.$t('dialogScheduleACheckMessage')}`;
      }

      return `Editing is not possible at the moment. If you change something and hit 'save', it will create a new monitor.`;
    },
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
  },
  methods: {
    createSchedule() {
      this.isLoading = true;
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      this.$store.dispatch('scheduler/createSchedule', dialogProps);
      this.showDialog = false;
      this.isLoading = false;
    },
    async setMode() {
      let mode = 'create';
      const dialogProps = this.$store.getters['dialog/dialogProps'];
      if (dialogProps.scheduleId) {
        mode = 'edit';
      }

      if (dialogProps.crawlerConfigs.crawlRequestIdToCompare) {
        this.onAComparisonPageAlready = true;
      }
      await this.$store.commit('scheduler/setMode', mode);
    },
  },
};
</script>
