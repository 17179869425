<template>
  <div>
    <v-bottom-navigation grow>
      <v-btn class="text-break text-truncate" value="imprint" :to="VUE_APP_URL_LEGAL_IMPRINT">{{ $t('imprint') }}</v-btn>
      <v-btn class="text-break text-truncate" value="data_privacy" :to="VUE_APP_URL_LEGAL_PRIVACY">{{ $t('dataprivacy') }}</v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.name != 'xs'"
        class="text-break text-truncate"
        value="data_privacy"
        :to="VUE_APP_URL_LEGAL_TOS"
        >{{ $t('tos') }}</v-btn
      >
      <v-btn value="refund_policy" :to="VUE_APP_URL_LEGAL_REFUND">Fair Refund Policy</v-btn>
      <v-btn v-if="$vuetify.breakpoint.name != 'xs'" class="text-break text-truncate" :to="VUE_APP_URL_ABOUT_THIS_TOOL">{{
        $t('aboutThisTool')
      }}</v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation grow v-if="$vuetify.breakpoint.name === 'xs'">
      <v-btn class="text-break text-truncate" value="data_privacy" :to="VUE_APP_URL_LEGAL_TOS">{{ $t('tos') }}</v-btn>
      <v-btn class="text-break text-truncate" :to="VUE_APP_URL_ABOUT_THIS_TOOL">{{ $t('aboutThisTool') }}</v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({}),
  computed: {
    VUE_APP_URL_ABOUT_THIS_TOOL: () => process.env.VUE_APP_URL_ABOUT_THIS_TOOL,
    VUE_APP_URL_LEGAL_TOS: () => process.env.VUE_APP_URL_LEGAL_TOS,
    VUE_APP_URL_LEGAL_REFUND: () => process.env.VUE_APP_URL_LEGAL_REFUND,
    VUE_APP_URL_LEGAL_IMPRINT: () => process.env.VUE_APP_URL_LEGAL_IMPRINT,
    VUE_APP_URL_LEGAL_PRIVACY: () => process.env.VUE_APP_URL_LEGAL_PRIVACY,
  },
};
</script>
