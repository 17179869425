import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import message from './modules/message';
import summary from './modules/summary';
import dialog from './modules/dialog';
import scheduler from './modules/scheduler';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
    message,
    summary,
    dialog,
    scheduler,
  },
  strict: debug,
});
