<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent>
      <v-card>
        <v-card-title class="headline">Differences Detected</v-card-title>
        <v-data-table
          dense
          :sort-by="['operation', 'key']"
          :sort-desc="[true, false]"
          loading-text="A good page crawl needs a couple of minutes. Please be patient."
          :headers="headers"
          :items="tableItems"
          :items-per-page="itemsPerPage"
          no-data-text="Tracking"
          class="elevation-0"
          :footer-props="{
            itemsPerPageOptions: [50, 100, -1],
          }"
        >
        </v-data-table>
        <v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="showDialog = false"> close </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DialogComparisonDetails',
  computed: {
    showDialog: {
      get() {
        return this.$store.getters['dialog/showDialog'];
      },
      set(newValue) {
        this.$store.commit('dialog/setShowDialog', newValue);
      },
    },
    tableItems() {
      const comparisonDetails = this.$store.getters['dialog/dialogProps'];
      if (comparisonDetails && comparisonDetails.rowData) {
        return comparisonDetails.rowData;
      }
      return [];
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 50,
      headers: [
        {
          text: this.$t('dialogCompDetailsTableAction'),
          value: 'operation',
        },
        {
          text: this.$t('type'),
          value: 'key',
        },
        {
          text: this.$t('before'),
          value: 'before',
        },
        {
          text: this.$t('new'),
          value: 'new',
        },
      ],
    };
  },
  methods: {},
};
</script>
